<template lang="pug">
v-dialog(
  v-model="dialog",
  @click:outside="$emit('closeDialog')",
  @keydown.esc="$emit('closeDialog')",
  width="600px"
)
  v-card(flat, width="100%")
    v-card-title.text-center.primary.white--text(
      style="word-break: break-word"
    )
      v-row.justify-center
        v-col.col-auto
          | {{ course }}
    v-card-text
      ContactForm(:enrollmentCourse="course")
</template>

<script>
import ContactForm from "@/components/ContactForm";
export default {
  name: "ContactDialog",
  props: {
    course: String,
    dialog: Boolean,
  },
  components: {
    ContactForm,
  },
};
</script>
