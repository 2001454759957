<template lang="pug">
v-row.justify-center.align-center
  v-col.col-12
    v-img(contain, alt="Ego Logo", :src="egoLogo", height="150px")
  v-col.col-12.text-center
    h1
      | Bun venit!
  v-col.col-6
    v-divider
  v-col.col-12.text-center
    h2
      | Asociația EGOMUNDI
  v-col.col-12
    v-row.no-gutters.justify-center
      v-col.col-12.col-md-6.text-center
        p.title
          | Cu o experiență de peste {{ getDateDifference("2011-01-01") }} ani în diseminarea programelor de formare continuă pentru personalul didactic de predare din învățământul preuniversitar
    v-row.no-gutters.justify-center
      v-col.col-12.col-md-6.text-center
        p.subtitle-2
          | Suntem o echipă cu inițiativa de a explora, de a cunoaște și de a înțelege actul pedagogic, formată din cadre didactice cu experiență și performanțe din învățământul preuniversitar și universitar. Obiectivul principal al echipei îl reprezintă promovarea unui spirit didactic bazat pe o mentalitate educațională deschisă, flexibilă, care se sprijină pe experiența pedagogică, atât a formatorilor, cât și a cursanților noștri.
    v-row.no-gutters.justify-center.pt-5
      v-col.col-12.col-md-8.text-center
        .title
          | Competențele formatorilor Asociației Egomundi:
        dl.pl-0.subtitle-2
          li
            | implicare de peste {{ getDateDifference("2011-01-01") }} ani în activitatea de formare continuă a cadrelor didactice;
          li
            | experiență pedagogică de peste 20 ani în mediul universitar și preuniversitar;
          li
            | experiență în managementul carierei didactice;
          li
            | experiență de mentorat și coaching didactic;
          li
            | experiență de management și leadership educațional;
          li
            | experiență pedagogică europeană;
          li
            | experiență în managementul de proiect;
  v-col.col-12.text-center.py-10
    v-row.pb-10
      v-col.col-12.pb-0
        h1
          | Ofertă cursuri online

      v-col.col-12.body-2.pt-0
        | Se desfășoară exclusiv online.

    v-row.ma-2.justify-center
      v-col.col-12.col-md-7.d-flex.flex-column(
        v-for="item in getCourses",
        :key="item.id"
      )
        v-card.elevation-5.flex.d-flex.flex-column.align-center(
          color="transparent"
        )
          v-card-title.primary.flex.justify-center.align-start.white--text(
            style="word-break: break-word; flex-grow: 1; width: 100%"
          )
            h4.mb-0
              | {{ item.title }}
          v-card-text.pa-4
            v-row
              v-col.col-12.subtitle-2.caption(v-if="item.accreditation")
                | {{ item.accreditation }}
              v-col.col-12.subtitle-2.subtitle-1.black--text(v-if="item.price")
                | {{ item.price }}
          v-card-actions.pa-4
            v-row
              v-col.col-12
                v-btn.text-capitalize.primary(
                  block,
                  rounded,
                  @click="enableContactDialog(item.title, 'course')"
                )
                  | Înscrieri
              v-col.col-12
                v-btn.text-capitalize.primary--text(
                  link,
                  rounded,
                  text,
                  block,
                  :to="{ path: 'cursuri', hash: `${item.id}` }"
                )
                  | Mai mult...

  v-col.col-12
    v-row.justify-center.no-gutters
      v-col.col-12.col-md-10
        p.subtitle-2
          | În anul școlar 2022-2023 toate cursurile din cadrul ASOCIAȚIEI EGOMUNDI se vor desfășura online, 50% din program va fi online meeting, iar 50% activități pe platformă.

  v-col.col-12.py-5.mb-10
    v-row.justify-center.text-center
      v-col.col-12.pb-0
        h1
          | Ofertă workshopuri

      v-col.col-12.body-2.pt-0
        | Se desfășoară față în față sau online.

    v-row.justify-center.text-center
      v-col.col-12.title
        | Tematica workshopurilor poate fi:

    v-row.justify-center.text-center
      v-col.col-12
        dl
          li(v-for="item in getWorkshops", :key="item.id")
            | {{ item.title }}

      v-col.col-12
        | * Tematica poate fi adaptată în funcție de cerințele instituției. *

      v-col.col-auto
        v-row
          v-col.col-6
            v-btn.text-capitalize.primary(
              block,
              rounded,
              @click="enableContactDialog('Cerere ofertă workshop', 'workshop')"
            )
              | Cere ofertă
          v-col.col-6
            v-btn.text-capitalize.primary--text(
              link,
              rounded,
              text,
              block,
              :to="{ path: 'workshopuri' }"
            )
              | Mai mult...

  ContactDialog(
    :course="selectedCourse",
    :dialog="contactDialog",
    @closeDialog="disableContactDialog"
  )
</template>

<script>
import { mapGetters } from "vuex";
import logo from "@/assets/no-bg-logo.png";
import ContactDialog from "@/dialogs/ContactDialog";

export default {
  name: "HomeView",
  components: {
    ContactDialog,
  },
  data: () => ({
    selectedCourse: "",
    contactDialog: false,
  }),
  computed: {
    academicYear() {
      let year = new Date().getFullYear();
      let lastyear = new Date().getFullYear() - 1;
      let range = [];
      let lastrange = [];
      let academicYear = [];
      lastrange.push(lastyear);
      range.push(year);
      for (let i = 1; i < 7; i++) {
        lastrange.push(lastyear + i);
        range.push(year + i);
        academicYear.push(
          lastrange[i - 1] + "-" + lastrange[i].toString().slice(-2)
        );
      }
      return academicYear;
    },
    activityStart() {
      let startDate = new Date("2011-01-01");
      let now = new Date();
      let year = now.getFullYear();
      let years = year - startDate.getFullYear();
      startDate = new Date(startDate.getTime()); // clone
      startDate.setFullYear(year);
      return now >= startDate ? years : years - 1;
    },
    egoLogo() {
      return logo;
    },
    ...mapGetters(["getCourses", "getWorkshops"]),
  },
  methods: {
    disableContactDialog() {
      this.contactDialog = false;
      this.selectedCourse = "";
    },
    enableContactDialog(course, type) {
      let dialogTitle =
        type === "course" ? `ÎNSCRIERE ${course}` : "CERERE OFERTĂ WORKSHOP";

      this.contactDialog = true;
      this.selectedCourse = dialogTitle;
    },
    hasDivider(index, listLength) {
      return listLength - 1 !== index;
    },
    getDateDifference(date) {
      let startDate = new Date(date);
      let now = new Date();
      let year = now.getFullYear();
      let years = year - startDate.getFullYear();
      startDate = new Date(startDate.getTime()); // clone
      startDate.setFullYear(year);
      return now >= startDate ? years : years - 1;
    },
  },
};
</script>

<style lang="stylus" scoped>
.same-height-card-title
  height 100px
  align-items start
</style>
