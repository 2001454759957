<template lang="pug">
v-row.justify-center
  v-col.col-12
    v-row.justify-center.text-center.pt-10
      v-col.col-12.pb-0
        h1
          | Workshopuri

      v-col.col-12.body-2.pt-0
        | Se desfășoară față în față sau online.

      v-col.col-6
        v-divider

      v-col.col-12.text-center
        v-row.justify-center
          v-col.col-12.col-sm-6
            p
              | Vă propunem pachete de workshopuri pentru dezvoltarea competențelor
              | cadrelor didactice și/sau elevilor în vederea prevenirii abandonului
              | școlar, care sunt aplicabile în cadrul proiectelor PNRAS.

            p
              | Workshopurile vor fi structurate în funcție de programul specific al proiectelor.

  v-col.col-12
    v-row.justify-center
      v-col.col-12.col-md-7.d-flex.flex-column
        v-card.flex.d-flex.flex-column(elevation="10", color="transparent")
          v-card-title.primary.justify-center.white--text(
            style="word-break: break-word"
          )
            v-row.justify-center
              v-col.col-auto.text-center
                | Workshopurile pentru cadre didactice vizează

          v-card-text.flex.py-5
            dl
              li
                | Dezvoltarea competențelor digitale;
              li
                | Dezvoltarea competențelor de comunicare;
              li
                | Dezvoltarea competențelor de lucru în echipă;
              li
                | Dezvoltarea competențelor de organizare și planificare;
              li
                | Dezvoltarea competențelor de adaptare la schimbare;
              li
                | Dezvoltarea competențelor de rezolvare a problemelor;
              li
                | Dezvoltarea competențelor de învățare continuă;
              li
                | Dezvoltarea competențelor de creativitate;
              li
                | Dezvoltarea competențelor de încredere în sine;
              li
                | Dezvoltarea competențelor de asumare a responsabilității;

          v-card-actions.pa-5
            v-row.justify-center
              v-col.col-auto
                v-btn.text-capitalize.primary(
                  block,
                  rounded,
                  @click="enableContactDialog('Cerere ofertă workshop', 'workshop')"
                )
                  | Cere ofertă

      v-col.col-12.col-md-7.d-flex.flex-column
        v-card.flex.d-flex.flex-column(elevation="10", color="transparent")
          v-card-title.primary.justify-center.white--text(
            style="word-break: break-word"
          )
            v-row.justify-center
              v-col.col-auto.text-cente
                | Workshopurile pentru elevi vizează

          v-card-text.flex.py-5
            v-row
              v-col.col-auto
                ul
                  li
                    | Dezvoltarea spiritului de echipă;
                  li
                    | Dezvoltarea competențelor de comunicare;
                  li
                    | Dezvoltarea toleranței și acceptării diferențelor de opinie;
                  li
                    | Dezvoltarea autocunoașterii
                  li
                    | Dezvoltarea încrederii în sine;
                  li
                    | Dezvoltarea atitudinii pozitive față de școală;
                  li
                    | Dezvoltarea sentimentului de apartenență;
                  li
                    | Dezvoltarea empatiei;
                  li
                    | Dezvoltarea creativității;
                  li
                    | Dezvoltarea spiritului de inițiativă;
                  li
                    | Dezvoltarea gândirii critice;
                  li
                    | Dezvoltarea competențelor digitale;

          v-card-actions.pa-5
            v-row.justify-center
              v-col.col-auto
                v-btn.text-capitalize.primary(
                  block,
                  rounded,
                  @click="enableContactDialog('Cerere ofertă workshop', 'workshop')"
                )
                  | Cere ofertă

  //- v-col.col-auto
    v-row
      v-col.col-6
        v-btn.text-capitalize.primary(
          block,
          rounded,
          @click="enableContactDialog('Cerere ofertă workshop', 'workshop')"
        )
          | Cere ofertă

  ContactDialog(
    :course="selectedCourse",
    :dialog="contactDialog",
    @closeDialog="disableContactDialog"
  )
</template>

<script>
import ContactDialog from "@/dialogs/ContactDialog";

export default {
  name: "WorkshopsView",
  components: { ContactDialog },
  data: () => ({
    selectedCourse: "",
    contactDialog: false,
  }),
  methods: {
    disableContactDialog() {
      this.contactDialog = false;
      this.selectedCourse = "";
    },
    enableContactDialog(course, type) {
      let dialogTitle =
        type === "course" ? `ÎNSCRIERE ${course}` : "CERERE OFERTĂ WORKSHOP";

      this.contactDialog = true;
      this.selectedCourse = dialogTitle;
    },
  },
};
</script>
