/* eslint-disable no-restricted-globals */
const domainHosts = {
  local: ["localhost", "127.0.0.1"],
  prod: ["ego-prod-fe.web.app", "ego-prod-fe.firebaseapp.com", "egomundi.ro"],
};

const environment = Object.entries(domainHosts).find((hostname) => {
  let partialHostName = location.hostname;

  return hostname[1].includes(partialHostName);
})[0];

export { environment };
