<template lang="pug">
#g-recaptcha.g-recaptcha(:data-sitekey="sitekey", data-size="invisible")
</template>

<script>
export default {
  data() {
    return {
      sitekey: "6LfVaPIhAAAAAEY39CDcxPaOT_c0MFw5vZ4hZQ49",
      vueWidgtId: 0,
    };
  },
  methods: {
    //some vuejs methods
    execute() {
      window.grecaptcha.execute(this.vueWidgtId);
    },
    reset() {
      //reset using vuejs
      window.grecaptcha.reset(this.vueWidgtId);
    },
    render() {
      //render Functions using vuejs
      if (!window.grecaptcha) return;
      window.grecaptcha.ready(() => {
        console.log("ready");
        this.vueWidgtId = window.grecaptcha.render("g-recaptcha", {
          sitekey: this.sitekey,
          callback: (results) => {
            this.$emit("verify", results);
            this.reset();
          },
        });
      });
    },
  },
  mounted() {
    this.render();
  },
};
</script>
