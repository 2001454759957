<template lang="pug">
v-row.justify-center.align-center.pt-10
  v-col.col-12.text-center
    h1
      | Plata
  v-col.col-12.col-sm-6(v-if="!successPayload")
    v-card
      v-card-text
        v-row
          v-col.col-12
            v-alert(type="info", outlined)
              span
                | Pentru a efectua o plată, vă rugăm să completați formularul de mai jos, ținând cont de următoarele:
              ul
                li
                  | toate câmpurile sunt obligatorii;
                li
                  | datele din formular trebuie să fie reale;
                li
                  | formularul nu acceptă caractere speciale și/sau diacritice (exemplu: ă, â, î, ș, ț, -, $ etc.);
          v-col.col-12
            v-form(
              ref="form",
              v-model="form",
              lazy-validation,
              :disabled="loadingSubmit"
            )
              v-row
                v-col.col-12
                  v-select(
                    clearable,
                    v-model="course",
                    :error-messages="courseErrors",
                    :items="getCourses",
                    :item-text="getCourseTitle",
                    item-value="id",
                    label="Curs",
                    required,
                    @click="$v.course.$touch()"
                  )

                v-col.col-12
                  v-checkbox(
                    v-model="partialPaymentCheck",
                    label="Doresc să plătesc o sumă parțială"
                  )

                v-col.col-12.col-md-6(v-if="partialPaymentCheck")
                  v-text-field(
                    v-model="partialPayment",
                    :error-messages="partialPaymentErrors",
                    label="Suma (RON)",
                    type="number",
                    required,
                    @input="$v.partialPayment.$touch()",
                    @blur="$v.partialPayment.$touch()"
                  )

                v-col.col-6.d-none.d-md-block(v-if="partialPaymentCheck")

                v-col.col-12.col-md-6
                  v-text-field(
                    v-model="lastName",
                    :error-messages="lastNameErrors",
                    label="Nume",
                    required,
                    @input="$v.lastName.$touch()",
                    @blur="$v.lastName.$touch()"
                  )

                v-col.col-12.col-md-6
                  v-text-field(
                    v-model="firstName",
                    :error-messages="firstNameErrors",
                    label="Prenume",
                    required,
                    @input="$v.firstName.$touch()",
                    @blur="$v.firstName.$touch()"
                  )

                v-col.col-12.col-md-6
                  v-text-field(
                    v-model="email",
                    :error-messages="emailErrors",
                    label="E-mail",
                    required,
                    @input="$v.email.$touch()",
                    @blur="$v.email.$touch()"
                  )

                v-col.col-12.col-md-6
                  v-text-field(
                    v-model="phone",
                    :error-messages="phoneErrors",
                    label="Telefon",
                    required,
                    @input="$v.phone.$touch()",
                    @blur="$v.phone.$touch()"
                  )

                v-col.col-12
                  v-text-field(
                    v-model="address",
                    :error-messages="addressErrors",
                    label="Adresa",
                    required,
                    @input="$v.address.$touch()",
                    @blur="$v.address.$touch()"
                  )

                v-col.col-12.col-md-6
                  v-text-field(
                    v-model="town",
                    :error-messages="townErrors",
                    label="Oras",
                    required,
                    @input="$v.town.$touch()",
                    @blur="$v.town.$touch()"
                  )

                v-col.col-12.col-md-6
                  v-text-field(
                    v-model="county",
                    :error-messages="countyErrors",
                    label="Judet",
                    required,
                    @input="$v.county.$touch()",
                    @blur="$v.county.$touch()"
                  )

                v-col.col-12(v-if="shorthandCourses.length")
                  v-row.justify-center.text-center.pa-3
                    v-col.col-12
                      h3
                        | Sumar comanda
                    v-col.col-12
                      v-list-item-group
                        v-list-item(
                          v-for="(course, index) in shorthandCourses",
                          :key="index",
                          disabled
                        )
                          v-list-item-content
                            v-list-item-title
                              | {{ partialPayment ? `Plată parțială - ${course.title}` : course.title }}
                            v-list-item-subtitle
                              | {{ partialPayment ? partialPayment : course.price }} RON
                    v-col.col-12
                      h3
                        span(v-if="partialPayment")
                          | Total: {{ partialPayment }} RON
                        span(v-else)
                          | Total: {{ shorthandCourses.reduce((acc, course) => (course?.price ? acc + course.price : 0), 0) }} RON

                v-col.col-12
                  v-row.justify-center.text-center.pa-3
                    v-col.col-12.pb-0
                      v-checkbox(
                        v-model="privacyPolicy",
                        required,
                        :error-messages="privacyPolicyErrors",
                        @change="$v.privacyPolicy.$touch()"
                      )
                        template(v-slot:label)
                          span.mr-1
                            | Sunt de acord cu
                          a(
                            href="/politica-de-confidentialitate",
                            target="_blank",
                            @click.stop
                          )
                            | Politica de confidențialitate

                    v-col.col-12.pt-0
                      v-checkbox(
                        v-model="termsAndConditions",
                        required,
                        :error-messages="termsAndConditionsErrors",
                        @change="$v.termsAndConditions.$touch()"
                      )
                        template(v-slot:label)
                          span.mr-1
                            | Sunt de acord cu
                          a(
                            href="/termeni-si-conditii",
                            target="_blank",
                            @click.stop
                          )
                            | Termenii și condițiile

                    v-col.col-6
                      v-btn.mr-4.success(
                        rounded,
                        block,
                        @click="submit",
                        :disabled="!privacyPolicy || !termsAndConditions || $v.$error",
                        :loading="loadingSubmit"
                      )
                        | plătește

                    v-spacer

                    v-col.col-6
                      v-btn.warning(
                        rounded,
                        block,
                        @click="clear",
                        :loading="loadingSubmit"
                      )
                        | șterge

            Recaptcha(ref="recaptcha", @verify="submitRecaptcha")

  v-col.col-12.col-sm-6(v-else)
    v-alert(
      type="error",
      elevation="2",
      outlined,
      dismissible,
      v-if="successPayload && successPayload.actionCode !== 0"
    )
      template(v-slot:close="{ toggle }")
        v-icon(@click="closeAlert(toggle)")
          | fa fa-window-close
      span
        | Plata nu a putut fi efectuată. {{ successPayload && successPayload.actionCodeDescription }}
      span(
        v-if="(successPayload && successPayload.actionCode === 803) || successPayload.actionCode === 804 || successPayload.actionCode === 913"
      )
        | Vă rugăm să contactați banca emitentă sau reîncercați plata cu un alt card.

    v-alert(type="success", elevation="2", outlined, dismissible, v-else)
      template(v-slot:close="{ toggle }")
        v-icon(@click="closeAlert(toggle)")
          | fa fa-window-close
      | Plata a fost efectuată cu succes!
      br
      br
      | Număr comandă: {{ successPayload && successPayload.orderNumber }}
      br
      br
      | Descrierea plății: {{ successPayload && successPayload.orderDescription }}
      br
      br
      | Suma plătită: {{ successPayload && successPayload.amount / 100 }}RON
      br
      br
      | Vă mulțumim!
      br
      br
      | În curând veți primi un e-mail cu detaliile plății.
</template>

<script>
import { mapGetters } from "vuex";
import { environment } from "@/config/dotEnv";
import { functions } from "@/config/firebaseConfig";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";

import { v4 as uuidv4 } from "uuid";

import {
  required,
  maxLength,
  minLength,
  email,
  numeric,
  sameAs,
} from "vuelidate/lib/validators";
import Recaptcha from "@/components/ReCaptcha";

const otherFieldContainsMe = (value, vm) => {
  if (vm.message || vm.course) return true;
  return false;
};

const checkForSpecialCharacters = (value) => {
  const regex = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;
  return regex.test(value);
};

export default {
  name: "PaymentView",
  mixins: [validationMixin],
  components: {
    Recaptcha,
  },
  validations() {
    return {
      firstName: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(3),
        checkForSpecialCharacters,
      },
      lastName: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(3),
        checkForSpecialCharacters,
      },
      email: { required, email },
      partialPayment: this.partialPaymentCheck
        ? { required, numeric, maxLength: maxLength(4) }
        : {},
      phone: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      address: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(3),
        checkForSpecialCharacters,
      },
      town: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(3),
        checkForSpecialCharacters,
      },
      county: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(3),
        checkForSpecialCharacters,
      },
      course: {
        otherFieldContainsMe,
      },
      privacyPolicy: {
        sameAs: sameAs(() => true),
      },
      termsAndConditions: {
        sameAs: sameAs(() => true),
      },
    };
  },
  data: () => ({
    form: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: null,
    address: "",
    town: "",
    county: "",
    course: null,
    partialPayment: null,
    partialPaymentCheck: false,
    privacyPolicy: false,
    termsAndConditions: false,
    loadingSubmit: false,
    successPayload: null,
  }),
  async mounted() {
    const router = this.$router;

    const { query } = router.currentRoute;

    if (query && query.orderId) {
      await this.getSuccesPayload(query);
    }
  },
  computed: {
    countyErrors() {
      const errors = [];
      if (!this.$v.county.$dirty) return errors;
      !this.$v.county.checkForSpecialCharacters &&
        errors.push(
          "Județul nu poate conține caractere speciale și/sau diacritice."
        );
      !this.$v.county.minLength &&
        errors.push("Județul trebuie să conțină minim 3 caractere.");
      !this.$v.county.required && errors.push("Județul este obligatoriu.");
      return errors;
    },
    townErrors() {
      const errors = [];
      if (!this.$v.town.$dirty) return errors;
      !this.$v.town.checkForSpecialCharacters &&
        errors.push(
          "Orașul nu poate conține caractere speciale și/sau diacritice."
        );
      !this.$v.town.minLength &&
        errors.push("Orașul trebuie să conțină minim 3 caractere.");
      !this.$v.town.required && errors.push("Orașul este obligatoriu.");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.checkForSpecialCharacters &&
        errors.push(
          "Prenumele nu poate conține caractere speciale și/sau diacritice."
        );
      !this.$v.firstName.maxLength &&
        errors.push("Prenumele trebuie să conțină maxim 20 caractere.");
      !this.$v.firstName.minLength &&
        errors.push("Prenumele trebuie să conțină minim 3 caractere.");
      !this.$v.firstName.required && errors.push("Prenumele este obligatoriu.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.checkForSpecialCharacters &&
        errors.push(
          "Numele nu poate conține caractere speciale și/sau diacritice."
        );
      !this.$v.lastName.maxLength &&
        errors.push("Numele trebuie să conțină maxim 20 caractere.");
      !this.$v.lastName.minLength &&
        errors.push("Numele trebuie să conțină minim 3 caractere.");
      !this.$v.lastName.required && errors.push("Numele este obligatoriu.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push("Trebuie să fie o adresă de email validă.");
      !this.$v.email.required &&
        errors.push("Adresa de e-mail este obligatorie.");
      return errors;
    },
    partialPaymentErrors() {
      const errors = [];
      if (!this.$v.partialPayment.$dirty) return errors;
      !this.$v.partialPayment.numeric &&
        errors.push("Trebuie să fie un o sumă validă.");
      !this.$v.partialPayment.maxLength &&
        errors.push("Suma trebuie să conțină maxim 4 caractere.");
      !this.$v.partialPayment.required && errors.push("Suma este obligatorie.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.numeric &&
        errors.push("Trebuie sa fie un numar de telefon valid.");
      !this.$v.phone.maxLength &&
        errors.push(
          "Numarul de telefon trebuie să conțină maxim 10 caractere."
        );
      !this.$v.phone.minLength &&
        errors.push(
          "Numarul de telefon trebuie să conțină minim 10 caractere."
        );
      !this.$v.phone.required && errors.push("Telefon obligatoriu");
      return errors;
    },
    courseErrors() {
      const errors = [];
      if (!this.$v.course.$dirty) return errors;
      !this.$v.course.otherFieldContainsMe &&
        errors.push("Trebuie selectat un curs.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.checkForSpecialCharacters &&
        errors.push(
          "Adresa nu poate conține caractere speciale și/sau diacritice."
        );
      !this.$v.address.maxLength &&
        errors.push("Adresa trebuie să conțină maxim 40 caractere.");
      !this.$v.address.minLength &&
        errors.push("Adresa trebuie să conțină minim 3 caractere.");
      !this.$v.address.required && errors.push("Adresa este obligatorie.");
      return errors;
    },
    privacyPolicyErrors() {
      const errors = [];
      if (!this.$v.privacyPolicy.$dirty) return errors;
      !this.$v.privacyPolicy.sameAs &&
        errors.push(
          "Trebuie să fiți de acord cu Politica de confidențialitate."
        );
      return errors;
    },
    termsAndConditionsErrors() {
      const errors = [];
      if (!this.$v.termsAndConditions.$dirty) return errors;
      !this.$v.termsAndConditions.sameAs &&
        errors.push("Trebuie să fiți de acord cu Termenii și condițiile.");
      return errors;
    },
    shorthandCourses() {
      if (!this.course) return [];

      const selectedCourses = this.getCourses.filter(
        (course) => course.id === this.course
      );

      const shorthandCourses = selectedCourses.map((course) => ({
        title:
          course.title.charAt(0).toUpperCase() +
          course.title.slice(1).toLowerCase(),
        price: parseInt(course?.price?.split(" ")[0]),
      }));

      return shorthandCourses;
    },
    anyDirty() {
      return this.$v.$anyDirty;
    },
    ...mapGetters(["getCourses"]),
  },
  watch: {
    partialPaymentCheck(value) {
      if (!value) {
        this.partialPayment = null;
      }
    },
  },
  methods: {
    closeAlert(toggle) {
      this.successPayload = null;

      if (!this.$route.path.includes("plata")) {
        this.$router.push({ query: null });
      }

      toggle();
    },
    getCourseTitle(course) {
      if (!course) return;

      const { price, title } = course;

      const capitalizedCourse =
        title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();

      const joinedPrice = price.split(" ").join("");

      if (this.partialPaymentCheck) return capitalizedCourse;

      return `${capitalizedCourse} - ${joinedPrice}`;
    },
    async submitRecaptcha(results) {
      if (environment === "local")
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);

      const verifyCaptcha = httpsCallable(functions, "verifyCaptcha");

      try {
        await verifyCaptcha({ token: results });
        this.initPayment();
      } catch (err) {
        this.loadingSubmit = false;
        this.showSnack({
          text: "Captcha nu a putut if verificata",
          color: "error",
          timeout: 3500,
        });
      }
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$error) return;

      this.loadingSubmit = true;

      this.executeRecaptcha();
    },
    clear() {
      this.$v.$reset();
      this.form = null;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = null;
      this.address = "";
      this.town = "";
      this.county = "";
      this.course = "";
    },
    normalizeString(string) {
      return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async initPayment() {
      let uuid = uuidv4();

      uuid = uuid.split("-");

      uuid.splice(1, 1);

      uuid = uuid.join("-");

      const date = new Date();

      const { year, month, day } = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        timestamp: date.getTime(),
      };

      const formattedDate = `${year}-${month}-${day}`;

      const paymentDescription = this.shorthandCourses
        .map((course) => course.title)
        .join(", ");

      const totalAmount = this.partialPayment
        ? this.partialPayment
        : this.shorthandCourses.reduce((acc, course) => acc + course.price, 0);

      const redirectUrl =
        environment === "local"
          ? "http://localhost:8080/plata"
          : "https://egomundi.ro/plata";

      const jsonData = {
        orderNumber: `${uuid}`,
        amount: totalAmount * 100,
        currency: 946,
        description: this.normalizeString(
          this.partialPayment
            ? `Plata partiala - ${paymentDescription}`
            : paymentDescription
        ),
        returnUrl: redirectUrl,
        orderBundle: {
          orderCreationDate: `${formattedDate}`,
          customerDetails: {
            contact: `${this.normalizeString(
              this.firstName
            )} ${this.normalizeString(this.lastName)}`,
            email: this.email,
            phone: `4${this.phone}`,
            deliveryInfo: {
              country: 642,
              city: this.normalizeString(this.town),
              postAddress: this.normalizeString(this.address),
              postAddress2: this.normalizeString(this.county),
            },
            billingInfo: {
              country: 642,
              city: this.normalizeString(this.town),
              postAddress: this.normalizeString(this.address),
              postAddress2: this.normalizeString(this.county),
            },
          },
        },
      };

      // const jsonData = {
      //   orderNumber: `${uuid}`,
      //   amount: 30000,
      //   currency: 946,
      //   description: "Cursuri",
      //   returnUrl: redirectUrl,
      //   orderBundle: {
      //     orderCreationDate: `${formattedDate}`,
      //     customerDetails: {
      //       email: "sardarescus@gmail.com",
      //       phone: `40749282118`,
      //       deliveryInfo: {
      //         country: 642,
      //         city: "Bucuresti",
      //         postAddress: "Aleea Codrii Neamtului",
      //         postAddress2: "Sector 3",
      //       },
      //       billingInfo: {
      //         country: 642,
      //         city: "Bucuresti",
      //         postAddress: "Aleea Codrii Neamtului",
      //         postAddress2: "Sector 3",
      //       },
      //     },
      //   },
      // };

      if (environment === "local")
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);

      const generatePayment = httpsCallable(functions, "generatePayment");

      try {
        const result = await generatePayment({
          params: jsonData,
          env: environment,
        });

        this.loadingSubmit = false;

        if (result.data.errorCode) {
          this.successPayload = {
            actionCode: result.data.errorCode,
            actionCodeDescription: result.data.errorCode,
          };
          return;
        }

        window.location.href = result.data.formUrl;
      } catch (error) {
        this.loadingSubmit = false;
        this.showSnack({
          text: error.message,
          color: "error",
          timeout: 3500,
        });
      }
    },
    async getSuccesPayload(query) {
      if (!query || !query.orderId || !query.token) return;

      const jsonData = {
        orderId: query.orderId,
        token: query.token,
        language: query.language,
      };

      if (environment === "local")
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);

      const queryPayment = httpsCallable(functions, "queryPayment");

      try {
        const result = await queryPayment({
          params: jsonData,
          env: environment,
        });
        this.loadingSubmit = false;

        this.successPayload = result.data;

        this.$router.push({ query: null });
      } catch (error) {
        this.loadingSubmit = false;
        this.showSnack({
          text: error.message,
          color: "error",
          timeout: 3500,
        });
      }
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    ...mapActions("snackbar", ["showSnack"]),
  },
};
</script>
