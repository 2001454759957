<template lang="pug">
v-row.justify-center.align-center.pt-10
  v-col.col-12.text-center
    h1
      | Contact
  v-col.col-12.col-sm-6
    ContactForm
</template>

<script>
import ContactForm from "@/components/ContactForm";
export default {
  name: "ContactView",
  components: { ContactForm },
};
</script>
