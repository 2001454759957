<template lang="pug">
#snackbar
  v-snackbar(v-model="show", :color="color", :timeout="timeout", bottom, right)
    | {{ text }}
    template(v-slot:action="{ attrs }")
      v-btn(dark, icon, v-bind="attrs", @click="show = false")
        v-icon(small)
          | fa-times
</template>

<script>
export default {
  name: "SnackBar",
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.text = state.snackbar.text;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.show = true;
      }
    });
  },
  data() {
    return {
      show: false,
      color: "",
      text: "",
      timeout: -1,
    };
  },
};
</script>
