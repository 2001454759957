<template lang="pug">
v-row.justify-center.align-center.pt-10
  v-col.col-12.text-center
    h1
      | Politica de Confidențialitate

  v-col.col-12.text-center.black--text
    h2
      | Asociația Egomundi
    h4
      | CUI: 28159309

  v-col.col-12.col-sm-6
    section
      p Ultima actualizare: 22.11.2023

      p
        | Asociația Egomundi acordă o atenție deosebită protecției datelor și securității informațiilor încă de la început. Prin urmare, ne angajăm să preluăm și să procesăm datele pe care ni le furnizați în conformitate cu legislația aplicabilă în țara noastră, inclusiv Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date ("GDPR").

      // Definirea Datelor Personale:
      p Definirea Datelor Personale:
      p
        | Începând cu data de 25 mai 2018, conform reglementărilor GDPR, considerăm datele personale orice informație care duce la identificarea unei persoane fizice.

      // Scopul și Temelia Prelucrării Datelor Personale:
      p Scopul și Temelia Prelucrării Datelor Personale:
      p
        | Pentru interacțiunea dumneavoastră cu conținutul website-ului nostru, colectăm și procesăm datele dvs. personale în vederea furnizării produselor și serviciilor solicitate.
        br
        | Utilizăm datele pentru comunicarea eficientă privind comenzile dumneavoastră, având ca temei contractul sau procesul de vânzare-cumpărare online sau offline.
        br
        | Îndeplinim obligațiile legale atunci când este necesar, transmitând datele autorităților naționale (Ministerul Educației) în scopul certificării procesului de formare.

      // Categorii de Date Personale Colectate:
      p Categorii de Date Personale Colectate:
      p
        | Informațiile preluate includ nume, prenume, adresă, număr de telefon, adresă de e-mail și altele furnizate direct de către utilizator.

      // Durata Păstrării Datelor:
      p Durata Păstrării Datelor:
      p
        | Păstrăm datele respectând obligațiile legale în vigoare. Datele vor fi reținute atâta timp cât este necesar pentru îndeplinirea scopului colectării.

      // Confidențialitate și Terte Părți:
      p Confidențialitate și Terte Părți:
      p
        | Ne angajăm să nu dezvăluim datele personale către terțe părți, cu excepția cazurilor prevăzute. În unele situații, datele pot fi transmise autorităților naționale în conformitate cu legile aplicabile.

      // Drepturile Utilizatorilor:
      p Drepturile Utilizatorilor:
      p
        | Conform legislației GDPR, aveți dreptul de a accesa, rectifica, retrage consimțământul, opune prelucrării și de a vă adresa autorităților competente.

      // Contact:
      p Contact:
      p
        | Pentru orice întrebări sau solicitări legate de prelucrarea datelor personale, vă rugăm să ne contactați la adresa egomundi@egomundi.ro sau la adresa poștală Strada Zefirului, Numărul 1, Bloc I8, Scara 1, Apartament 7, Călărași.

      // Apreciem încrederea acordată și vă mulțumim pentru colaborarea cu noi!
</template>

<script>
export default {
  name: "PrivacyPolicyView",
};
</script>
