<template lang="pug">
v-row.justify-center.align-center.pt-10
  v-col.col-12
    v-row.justify-center.text-center
      v-col.col-12.pb-0
        h1
          | Cursuri

      v-col.col-12.body-2.pt-0
        | Se desfășoară exclusiv online.
  v-col.col-6
    v-divider

  v-col.col-12
    v-row.no-gutters.justify-center
      v-col.col-12.col-sm-6.text-center
        p
          | Cursurile acreditate sunt rezultatul unei activități intense de mulți ani a întregii echipe, a unei colaborări și a unei relaționări permanente.
  v-col.col-12(
    v-for="(item, key) in getCourses",
    :key="item.id",
    :id="item.id"
  )
    v-row.no-gutters.justify-center
      v-col.col-12.col-md-7
        v-card(elevation="10", color="transparent")
          v-card-title.text-center.justify-center(
            :class="item.id === currentHash ? 'success' : 'primary'",
            style="word-break: break-word"
          )
            .white--text
              | {{ item.title }}
          v-card-subtitle.pt-8.text-center.justify-center.black--text
            | {{ item.accreditation }}

          v-card-text.black--text
            v-row
              v-col.col-12
                v-row
                  v-col.col-12.title
                    | Discipline:
                  v-col.col-12.pt-0.body-1
                    ol
                      li(v-for="(discipline, key) in item.disciplines")
                        | {{ discipline }}
              v-col.col-12
                v-row
                  v-col.col-12.title
                    | Competențe generale:
                  v-col.col-12.pt-0.body-1
                    ol
                      li(v-for="(competency, key) in item.competencies")
                        | {{ competency }}
              v-col.col-12.title.text-center.justify-center
                | Grupul țintă: Personal didactic de predare din învățământul preuniversitar
              v-col.col-12.title.text-center
                | {{ item.price }}

          v-card-actions.pa-5
            v-row.justify-center
              v-col.col-auto
                v-btn.text-capitalize.primary(
                  rounded,
                  @click="enableContactDialog(`ÎNSCRIERE ${item.title}`)"
                )
                  | Înscrieri
  ContactDialog(
    :course="selectedCourse",
    :dialog="contactDialog",
    title="ÎNSCRIERE",
    @closeDialog="disableContactDialog"
  )
</template>

<script>
import { mapGetters } from "vuex";
import ContactDialog from "@/dialogs/ContactDialog";

export default {
  name: "CoursesView",
  components: {
    ContactDialog,
  },
  data: () => ({
    selectedCourse: "",
    contactDialog: false,
  }),
  computed: {
    ...mapGetters(["getCourses"]),
    currentHash() {
      return this.$route.hash.split("#")[1];
    },
  },
  methods: {
    disableContactDialog() {
      this.contactDialog = false;
      this.selectedCourse = "";
    },
    enableContactDialog(course) {
      this.contactDialog = true;
      this.selectedCourse = course;
    },
  },
};
</script>
