import Vue from "vue";
import VueRouter from "vue-router";
import ContactView from "@/views/ContactView.vue";
import CoursesView from "@/views/CoursesView.vue";
import WorkshopsView from "@/views/WorkshopsView.vue";
import HomeView from "@/views/HomeView.vue";
import TermsAndConditionsView from "@/views/TermsAndConditionsView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import PaymentView from "@/views/PaymentView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "acasa",
    component: HomeView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/cursuri",
    name: "cursuri",
    component: CoursesView,
  },
  {
    path: "/workshopuri",
    name: "workshopuri",
    component: WorkshopsView,
  },
  {
    path: "/plata",
    name: "plata",
    component: PaymentView,
  },
  {
    path: "/termeni-si-conditii",
    name: "termeni-si-conditii",
    component: TermsAndConditionsView,
  },
  {
    path: "/politica-de-confidentialitate",
    name: "politica-de-confidentialitate",
    component: PrivacyPolicyView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

export default router;
