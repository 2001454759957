<template lang="pug">
v-footer(color="primary")
  v-row.justify-center.no-gutters.py-3
    v-col.col-10
      v-row.justify-center.no-gutters
        v-col.col-6.d-none.d-md-block
          v-row.no-gutters.text-right
            v-col.col-12
              v-btn(text, color="transparent", href="tel:0722172008")
                .white--text
                  | 0722172008

            v-col.col-12.white--text.body-2.px-4.font-weight-bold
              | CUI: 28159309

            v-col.col-12
              v-btn(
                text,
                color="transparent",
                :to="{ name: 'termeni-si-conditii' }"
              )
                .white--text.text-none
                  | Termeni și condiții

        v-col.col-6.d-none.d-md-block
          v-row.no-gutters
            v-col.col-12
              v-btn.text-lowercase(
                text,
                color="transparent",
                href="mailto:egomundi@egomundi.ro"
              )
                .white--text
                  | egomundi@egomundi.ro

            v-col.col-12.white--text.body-2.px-4.font-weight-bold
              | Str Zefirului, Nr 1, Bl I8, Sc 1, Ap 7, Călărași

            v-col.col-12
              v-btn(
                text,
                color="transparent",
                :to="{ name: 'politica-de-confidentialitate' }"
              )
                .white--text.text-none
                  | Politica de confidențialitate

        v-col.col-12.d-sm-none
          v-row.justify-center.no-gutters
            v-col.col-12
              v-row.justify-center
                v-col.col-auto
                  v-btn(text, color="transparent", href="tel:0722172008")
                    .white--text
                      | 0722172008

            v-col.col-12
              v-row.justify-center
                v-col.col-auto
                  v-btn.text-lowercase(
                    text,
                    color="transparent",
                    href="mailto:egomundi@egomundi.ro"
                  )
                    .white--text
                      | egomundi@egomundi.ro

            v-col.col-12
              v-row.justify-center
                v-col.col-auto
                  v-btn(
                    text,
                    color="transparent",
                    :to="{ name: 'politica-de-confidentialitate' }"
                  )
                    .white--text.text-none
                      | Politica de Confidențialitate

            v-col.col-12
              v-row.justify-center
                v-col.col-auto
                  v-btn(
                    text,
                    color="transparent",
                    :to="{ name: 'termeni-si-conditii' }"
                  )
                    .white--text.text-none
                      | Termeni și Condiții

            v-col.col-auto.white--text.body-2.px-4.font-weight-bold
              | CUI: 28159309

            v-col.col-auto.white--text.body-2.px-4.font-weight-bold
              | Str Zefirului, Nr 1, Bl I8, Sc 1, Ap 7, Călărași

        v-col.col-auto.px-3.white--text
          | <strong> Asociația Egomundi </strong>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    socialLinks: [
      {
        name: "Facebook",
        icon: "fa-brands fa-facebook",
      },
      {
        name: "LinkedIn",
        icon: "fa-brands fa-linkedin",
      },
      {
        name: "Instagram",
        icon: "fa-brands fa-instagram",
      },
    ],
  }),
};
</script>
