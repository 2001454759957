import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBQg4bJjCEd5grZ3E3denuhkP-yg82BOsI",
  authDomain: "ego-prod-fe.firebaseapp.com",
  projectId: "ego-prod-fe",
  storageBucket: "ego-prod-fe.appspot.com",
  messagingSenderId: "37554172134",
  appId: "1:37554172134:web:c85971864f8f43b7138b27",
};

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);

export { firebaseApp, functions };
