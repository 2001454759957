<template lang="pug">
v-app-bar(app, flat)
  v-tabs(grow, tile, color="primary", v-model="selectedRoute")
    v-tab.text-capitalize(
      v-for="(item, index) in routes",
      :key="index",
      :to="{ name: item.link }",
      link,
      exact,
      :value="selectedRoute"
    )
      .d-none.d-sm-block
        | {{ item.name }}
      v-icon.d-block.d-sm-none
        | {{ item.icon }}
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    routes: [
      { name: "Acasa", link: "acasa", icon: "fa fa-home" },
      { name: "Cursuri", link: "cursuri", icon: "fa fa-graduation-cap" },
      { name: "Workshopuri", link: "workshopuri", icon: "fa fa-clipboard" },
      { name: "Contact", link: "contact", icon: "fa fa-message" },
      { name: "Plata", link: "plata", icon: "fa fa-money-bill" },
    ],
  }),
  computed: {
    selectedRoute() {
      return this.$route.path;
    },
  },
};
</script>
