<template lang="pug">
v-card(:elevation="enrollmentCourse ? 0 : 2")
  v-card-text
    v-form(v-model="form", :disabled="loadingSubmit")
      .title.pt-3
        | Detalii personale

      v-row
        v-col.col-12
          v-text-field(
            v-model="lastName",
            :error-messages="lastNameErrors",
            :counter="20",
            label="Nume",
            required,
            @input="$v.lastName.$touch()",
            @blur="$v.lastName.$touch()"
          )

        v-col.col-12
          v-text-field(
            v-model="firstName",
            :error-messages="firstNameErrors",
            :counter="20",
            label="Prenume",
            required,
            @input="$v.firstName.$touch()",
            @blur="$v.firstName.$touch()"
          )

        v-col.col-12
          v-text-field(
            v-model="email",
            :error-messages="emailErrors",
            label="E-mail",
            required,
            @input="$v.email.$touch()",
            @blur="$v.email.$touch()"
          )

        v-col.col-12
          v-text-field(
            v-model="phone",
            :error-messages="phoneErrors",
            label="Telefon",
            required,
            @input="$v.phone.$touch()",
            @blur="$v.phone.$touch()"
          )

      v-divider.my-8

      .title.pt-3
        | Unitatea de învățământ

      v-text-field(
        v-model="school",
        :error-messages="schoolErrors",
        :counter="255",
        label="Numele unității de învățământ",
        required,
        @input="$v.school.$touch()",
        @blur="$v.school.$touch()"
      )

      v-row
        v-col.col-12
          v-text-field(
            v-model="town",
            :error-messages="townErrors",
            :counter="40",
            label="Localitate",
            required,
            @input="$v.town.$touch()",
            @blur="$v.town.$touch()"
          )

        v-col.col-12
          v-text-field(
            v-model="county",
            :error-messages="countyErrors",
            :counter="20",
            label="Județ",
            required,
            @input="$v.county.$touch()",
            @blur="$v.county.$touch()"
          )

      v-divider.my-8

      .title.pt-3(v-if="!enrollmentCourse")
        | Curs

      v-select(
        v-if="!enrollmentCourse",
        v-model="course",
        :error-messages="courseErrors",
        :items="getCourses",
        item-text="title",
        clearable,
        label="Curs(opțional)",
        @click="$v.course.$touch()"
      )

      v-divider.my-8(v-if="!enrollmentCourse")

      .title.pt-3
        | Mesaj

      v-textarea(
        v-model="message",
        :error-messages="messageErrors",
        counter,
        label="Mesaj(opțional)",
        @input="$v.message.$touch()",
        @blur="$v.message.$touch()"
      )

      v-row.justify-center.text-center.pa-3
        v-col.col-6
          v-btn.mr-4.success(
            rounded,
            block,
            @click="submit",
            :loading="loadingSubmit"
          )
            | trimite

        v-spacer

        v-col.col-6
          v-btn.warning(
            rounded,
            block,
            @click="clear",
            :loading="loadingSubmit"
          )
            | sterge

    Recaptcha(ref="recaptcha", @verify="submitRecaptcha")
</template>

<script>
import { mapGetters } from "vuex";
import { environment } from "@/config/dotEnv";
import { functions } from "@/config/firebaseConfig";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";

import {
  required,
  maxLength,
  minLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import Recaptcha from "@/components/ReCaptcha";

const otherFieldContainsMe = (value, vm) => {
  if (vm.message || vm.course) return true;
  return false;
};

export default {
  name: "ContactForm",
  mixins: [validationMixin],
  props: {
    enrollmentCourse: String,
  },
  components: {
    Recaptcha,
  },
  validations: {
    firstName: { required, maxLength: maxLength(20), minLength: minLength(3) },
    lastName: { required, maxLength: maxLength(20), minLength: minLength(3) },
    school: { required, minLength: minLength(10) },
    town: { required, maxLength: maxLength(40), minLength: minLength(3) },
    county: { required, maxLength: maxLength(20), minLength: minLength(3) },
    email: { required, email },
    phone: {
      required,
      numeric,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
    message: {
      maxLength: maxLength(255),
      minLength: minLength(10),
      otherFieldContainsMe,
    },
    course: {
      otherFieldContainsMe,
    },
  },
  data: () => ({
    form: null,
    firstName: "",
    lastName: "",
    email: "",
    school: "",
    town: "",
    county: "",
    phone: "",
    course: null,
    message: "",
    loadingSubmit: false,
  }),
  computed: {
    schoolErrors() {
      const errors = [];
      if (!this.$v.school.$dirty) return errors;
      !this.$v.school.minLength &&
        errors.push(
          "Numele unității de învățământ trebuie să conțină minim 10 caractere."
        );
      !this.$v.school.required &&
        errors.push("Numele unității de învățământ este obligatoriu.");
      return errors;
    },
    countyErrors() {
      const errors = [];
      if (!this.$v.county.$dirty) return errors;
      !this.$v.county.minLength &&
        errors.push("Județul trebuie să conțină minim 3 caractere.");
      !this.$v.county.required && errors.push("Județul este obligatoriu.");
      return errors;
    },
    townErrors() {
      const errors = [];
      if (!this.$v.town.$dirty) return errors;
      !this.$v.town.minLength &&
        errors.push("Localitatea trebuie să conțină minim 3 caractere.");
      !this.$v.town.required && errors.push("Localitatea este obligatorie.");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.maxLength &&
        errors.push("Prenumele trebuie să conțină maxim 20 caractere.");
      !this.$v.firstName.minLength &&
        errors.push("Prenumele trebuie să conțină minim 3 caractere.");
      !this.$v.firstName.required && errors.push("Prenumele este obligatoriu.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.maxLength &&
        errors.push("Numele trebuie să conțină maxim 20 caractere.");
      !this.$v.lastName.minLength &&
        errors.push("Numele trebuie să conțină minim 3 caractere.");
      !this.$v.lastName.required && errors.push("Numele este obligatoriu.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push("Trebuie să fie o adresă de email validă.");
      !this.$v.email.required &&
        errors.push("Adresa de e-mail este obligatorie.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.numeric &&
        errors.push("Trebuie sa fie un numar de telefon valid.");
      !this.$v.phone.maxLength &&
        errors.push(
          "Numarul de telefon trebuie să conțină maxim 10 caractere."
        );
      !this.$v.phone.minLength &&
        errors.push(
          "Numarul de telefon trebuie să conțină minim 10 caractere."
        );
      !this.$v.phone.required && errors.push("Telefon obligatoriu");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.maxLength &&
        errors.push("Mesajul trebuie să conțină maxim 255 caractere.");
      !this.$v.message.minLength &&
        errors.push("Mesajul trebuie să conțină minim 10 caractere.");
      !this.$v.message.otherFieldContainsMe &&
        errors.push("Trebuie selectat un curs sau completat un mesaj.");
      return errors;
    },
    courseErrors() {
      const errors = [];
      if (!this.$v.course.$dirty) return errors;
      !this.$v.course.otherFieldContainsMe &&
        errors.push("Trebuie selectat un curs sau completat un mesaj.");
      return errors;
    },
    ...mapGetters(["getCourses"]),
  },
  methods: {
    async submitRecaptcha(results) {
      if (environment === "local")
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);

      const verifyCaptcha = httpsCallable(functions, "verifyCaptcha");

      try {
        await verifyCaptcha({ token: results });
        this.sendMail();
      } catch (err) {
        this.loadingSubmit = false;
        this.showSnack({
          text: "Captcha nu a putut if verificata",
          color: "error",
          timeout: 3500,
        });
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.loadingSubmit = true;

      this.executeRecaptcha();
    },
    clear() {
      this.$v.$reset();
      this.firstName = "";
      this.lastName = "";
      this.school = "";
      this.town = "";
      this.county = "";
      this.email = "";
      this.phone = "";
      this.course = null;
      this.message = "";
    },
    async sendMail() {
      const formData = {
        firstName: this.firstName,
        lastName: this.lastName,
        school: this.school,
        town: this.town,
        county: this.county,
        email: this.email,
        phone: this.phone,
        course: this.course,
        message: this.message,
        subject: this.enrollmentCourse ? "Inscriere" : "Mesaj",
      };

      if (environment === "local")
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);

      const sendMail = httpsCallable(functions, "emailMessage");

      try {
        await sendMail(formData);
        this.loadingSubmit = false;
        this.showSnack({
          text: "Mesaj trimis.",
          color: "success",
          timeout: 3500,
        });
        this.clear();
      } catch (err) {
        this.loadingSubmit = false;
        this.showSnack({
          text: "Mesajul nu a putut fi trimis. Vă rugăm sa apelați persoana de contact.",
          color: "error",
          timeout: 3500,
        });
      }
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    ...mapActions("snackbar", ["showSnack"]),
  },
  watch: {
    enrollmentCourse: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return;
        this.course = value;
      },
    },
  },
};
</script>
