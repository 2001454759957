<template lang="pug">
v-app#app(:style="backgroundImageStyle")
  AppBar

  v-main
    v-container(fluid)
      router-view

  v-btn.success.floating-whatsapp(
    fixed,
    bottom,
    left,
    icon,
    fab,
    small,
    elevation="15",
    :href="whatsAppLink"
  )
    v-icon.white--text
      | fa-brands fa-whatsapp

  SnackBar
  AppFooter
</template>

<script>
import AppBar from "@/components/AppBar";
import AppFooter from "@/components/AppFooter";
import SnackBar from "@/components/SnackBar";
import backgroundImage from "./assets/backgroundImage.jpg";

export default {
  name: "App",
  components: { AppBar, AppFooter, SnackBar },
  data: () => ({}),
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    backgroundImg() {
      return backgroundImage;
    },
    whatsAppLink() {
      return `https://wa.me/40722172008/?text=${encodeURI(
        "Bună ziua. Am câteva nelămuriri. Mă puteți ajuta?"
      )}`;
    },
    backgroundImageStyle() {
      return `background-image: url(${this.backgroundImg}); background-size: cover;`;
    },
  },
};
</script>

<style lang="stylus">
#app
  min-height 100vh

  .floating-whatsapp
    bottom 15px

.grecaptcha-badge
  z-index 999
</style>
