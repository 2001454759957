import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stateCourses: [
      {
        id: "curs_1",
        title: "PREDAREA INOVATIVĂ ÎN ȘCOLILE DE SUCCES",
        accreditation:
          "Acreditat prin OM nr. 5291/ 04.08.2023 - 15 credite profesionale transferabile",
        price: "300 RON",
        competencies: [
          "Competențe de înțelegere și aprofundare a rolului predării creative în școala modernă",
          "Competențe de valorificare a tehnicilor educaționale inovative",
          "Competențe de înțelegere a potențialului educativ al comunicării și feedbackului",
        ],
        disciplines: [
          "Predarea creativă în școala modernă",
          "Tehnici educaționale de predare inovativă",
          "Comunicare și feedback în educația actuală",
        ],
      },
      {
        id: "curs_2",
        title: "INTELIGENȚA EMOTIONALĂ PENTRU EDUCAȚIE PERFOMANTĂ",
        accreditation:
          "Acreditat prin OM nr. 5291/ 04.08.2023 - 15 credite profesionale transferabile",
        price: "300 RON",
        competencies: [
          "Competențe de înțelegere și aplicare a dimensiunilor și valențelor inteligenței emoționale și culturii emoționale a profesorilor în actul educațional",
          "Competențe de valorificare a emoției și creativității în demersul didactic",
          "Competențe de înțelegere a motivării în și pentru cariera didactică prin asimilarea descriptorilor de performanță a profesorilor pentru o educație de succes",
        ],
        disciplines: [
          "Inteligența emoțională în educație",
          "Emoție și creativitate",
          "Educație pentru performanță",
        ],
      },
      {
        id: "curs_3",
        title: "MANAGEMENT MOTIVAȚIONAL ȘI PARTICIPATIV ÎN EDUCAȚIE",
        accreditation:
          "Acreditat prin OM nr. 4618/ 11.08.2021 - 30 credite profesionale transferabile",
        price: "500 RON",
        competencies: [
          "Competenţe de motivare, gestionare și integrare a resurselor umane",
          "Competenţe de relaţionare bazate pe inteligența emoțională",
          "Competenţe de comunicare şi relaţionare în managementul educațional",
          "Competenţe psiho-sociale utile în situații de criză si de negociere",
          "Competenţe de evaluare și aplicare a politicilor de marketing motivațional în educație",
          "Competenţe care vizează dezvoltarea instituţională prin intermediul proiectelor",
        ],
        disciplines: [
          "Managementul motivațional al resurselor umane în educație",
          "Managementul inteligenței emoționale",
          "Comunicarea managerială în educație",
          "Managementul crizei și al negocierii în domeniul educațional",
          "Marketing motivațional în educație",
          "Managementul de proiect în sistemul educațional",
        ],
      },
      {
        id: "curs_4",
        title: "MANAGEMENT ȘI LEADERSHIP EDUCAȚIONAL",
        accreditation:
          "Acreditat prin OM nr. 4618/ 11.08.2021 - 30 credite profesionale transferabile",
        price: "500 RON",
        competencies: [
          "Competenţe de organizare, îndrumare și control caracteristice unui management și leadership educațional eficient",
          "Competenţe de organizare, îndrumare și control caracteristice leadershipului alpha",
          "Competenţe psiho-sociale necesare progresului profesional",
          "Competenţe de gestionare a resurselor umane și de timp",
          "Competenţe care vizează dezvoltarea instituţională",
          "Competenţe de autoevaluare/evaluare pentru eficientizarea managementului calității",
        ],
        disciplines: [
          "Management si leadership eficient",
          "Leadership alpha pentru educație",
          "Managementul carierei didactice și progresului profesional",
          "Managementul timpului şi al priorităţilor în educație",
          "Cultura organizațională în educație",
          "Managementul calității și eficienței sistemului de educație",
        ],
      },
      {
        id: "curs_5",
        title: "PERSPECTIVE ALE DIDACTICII MODERNE",
        accreditation:
          "Acreditat prin OM nr. 4618/ 11.08.2021 - 30 credite profesionale transferabile",
        price: "500 RON",
        competencies: [
          "Competenţe care vizează înțelegerea dimensiunilor didacticii actuale",
          "Competenţe care vizează valorificarea velențelor educației moderne.",
          "Competenţe de valorificare a comunicării și feedbackului în educație",
          "Competenţe de predare interactiv-creativă",
          "Competențe de utilizare a metodelor și tehnicilor de învățare",
          "Competenţe de aplicare a diferitelor metode de evaluare ca o componentă reglatorie a predării",
        ],
        disciplines: [
          "Dimensiuni ale didacticii actuale",
          "Valențe ale educației moderne",
          "Comunicare și feedback în educația actuală",
          "Predarea interactiv-creativă in scoala modernă",
          "Strategii moderne de învățare",
          "Evaluare-componentă reglatorie în educație",
        ],
      },
    ],
    stateWorkshops: [
      {
        id: "workshop_1",
        title:
          "Managementul emoțiilor în relația profesor-elev pentru integrare și reușită școlară",
      },
      {
        id: "workshop_2",
        title: "Empatie și comunicare didactică eficientă în școala incluzivă",
      },
      {
        id: "workshop_3",
        title:
          "Activități de prevenție în vederea reducerii abandonului școlar",
      },
      {
        id: "workshop_4",
        title: "Emoție și creativitate pentru o școală prietenoasă",
      },
      {
        id: "workshop_5",
        title: "Violență și bullying în școală",
      },
    ],
  },
  getters: {
    getCourseById: (state) => (id) => {
      return state.todos.find((todo) => todo.id === id);
    },
    getCourses(state) {
      return state.stateCourses;
    },
    getWorkshopById: (state) => (id) => {
      return state.stateWorkshops.find((workshop) => workshop.id === id);
    },
    getWorkshops(state) {
      return state.stateWorkshops;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    snackbar: {
      namespaced: true,
      state: {
        text: "",
        color: "",
        timeout: "",
      },
      mutations: {
        SHOW_MESSAGE(state, payload) {
          state.text = payload.text;
          state.color = payload.color;
          state.timeout = payload.timeout;
        },
      },
      actions: {
        showSnack({ commit }, payload) {
          commit("SHOW_MESSAGE", payload);
        },
      },
    },
  },
});
