<template lang="pug">
v-row.justify-center.align-center.pt-10
  v-col.col-12.text-center
    h1
      | Termeni și condiții de utilizare a website-ului
  v-col.col-12.text-center.black--text
    h2
      | Asociația Egomundi
    h4
      | CUI: 28159309
  v-col.col-12.col-sm-6
    section
      // Acordul utilizatorului:
      p Acordul utilizatorului:
      p
        | Prin accesarea acestui website sau a oricărei pagini a acestuia, utilizatorul este de acord cu termenii și condițiile prezentate mai jos. Dacă nu sunteți de acord cu acești termeni și condiții, vă rugăm să nu accesați acest website.

      // Scop informativ general:
      p Scop informativ general:
      p
        | Asociația Egomundi pune la dispoziție informațiile de pe acest website în scop informativ general. Deși se depune efort pentru asigurarea exactității acestora la momentul publicării, nu se oferă garanții în acest sens.

      // Riscuri și responsabilități:
      p Riscuri și responsabilități:
      p
        | Asociația Egomundi nu poate fi făcută responsabilă pentru pierderile sau daunele suferite de utilizatori în urma folosirii informațiilor de pe website. Nu se oferă garanții cu privire la acuratețea, utilitatea sau exhaustivitatea informațiilor sau materialelor prezentate.

      // Responsabilitatea utilizatorului:
      p Responsabilitatea utilizatorului:
      p
        | Utilizatorul accesează website-ul pe propria răspundere, iar materialele și informațiile sunt furnizate în scop de informare generală, fără nicio garanție explicită sau implicită.

      // Schimbarea termenilor și condițiilor:
      p Schimbarea termenilor și condițiilor:
      p
        | Asociația Egomundi își rezervă dreptul de a schimba termenii, condițiile și politicile fără notificare prealabilă. Utilizatorii sunt îndemnați să revizuiască această secțiune periodic pentru a fi la curent cu eventualele modificări.

      // Politica de achiziție, livrare și retur a serviciilor:
      p Politica de achiziție, livrare și retur a serviciilor:
      p
        | Asociația Egomundi oferă cursuri acreditate de Ministerul Educației și workshop-uri, iar tarifele și condițiile de participare sunt specificate pe paginile corespunzătoare.
        br
        | Utilizatorii pot alege între plata cu card bancar sau ordin de plată, conform procedurilor indicate pe site.
        br
        | Serviciile achiziționate online cu card bancar sunt livrate exclusiv prin platforma e-learning a furnizorului.

      // Obligațiile părților în cadrul serviciilor de formare:
      p Obligațiile părților în cadrul serviciilor de formare:
      p
        | Furnizorul se obliga:
        br
        | a) Sa asigure desfasurarea programului de formare continua, cu respectarea normelor legale si a metodologilor in materie, punand accent pe calitatea formarii;
        br
        | b) Sa organizeze sesiunea de evaluare finala;
        br
        | c) Sa elibereze atestatele/certificatele de formare continua absolventilor.
        br
        br
        | Beneficiarul se obliga:
        br
        | a) Sa isi asume raspunderea ca toate documentele si informatiile furnizate sunt conforme cu realitatea;
        br
        | b) Sa frecventeze programul de formare continua;
        br
        | c) Sa depuna integral si la timp materialele pentru evaluarea de progres si pentru evaluarea finala;
        br
        | d) Sa aplice si sa valorifice competentele dobandite in activitatea profesionala.

      // Politica de anulare/retur a cursurilor:
      p Politica de anulare/retur a cursurilor:
      p
        | Clienții pot reprograma participarea la un program de formare în cazul imposibilității de participare din motive obiective.
        br
        | Returnarea integrală a contravalorii cursului se poate realiza doar în urma unei solicitări primite cu cel puțin 10 zile înainte de începerea cursului.
        br
        | În situația excepțională în care cursul nu se desfășoară din vina furnizorului, Asociația Egomundi se angajează să restituie integral contravaloarea.

      p Solutionarea litigiilor:
      p
        | Orice litigiu între utilizator și Asociație va fi soluționat pe cale amiabilă. Dacă nu se ajunge la o înțelegere, litigiul va fi supus instanțelor competente conform legislației în vigoare.

      // Acești termeni și condiții reprezintă angajamentul între utilizator și Asociație și pot fi actualizați fără notificare prealabilă. Utilizatorii sunt încurajați să revină periodic pentru a se informa cu privire la eventualele modificări.
</template>

<script>
export default {
  name: "TermsAndConditionsView",
};
</script>
